import { ReactElement, useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  Tbody,
  Text,
  Tr,
} from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import WeatherSunnyImgUrl from "../../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../../assets/images/weather/rainy.png";
import { useAtom } from "jotai";
import {
  co2ClassificationThresholdHigh,
  co2ClassificationThresholdLow,
} from "../../store";
import { HeadingWithVerticalLine } from "../atoms/HeadingWithVerticalLine";
import { TFHCustomTd } from "../atoms/TFHCustmTd";

type ClassType = "A" | "B" | "C";

function useDetermineClass(): (score: number) => ClassType {
  const [thresholdLow] = useAtom(co2ClassificationThresholdLow);
  const [thresholdHigh] = useAtom(co2ClassificationThresholdHigh);

  return (score: number): ClassType => {
    if (score > thresholdHigh) {
      return "A";
    } else if (score > thresholdLow && score <= thresholdHigh) {
      return "B";
    } else if (score >= 0 && score <= thresholdLow) {
      return "C";
    } else {
      throw new Error("Score is out of the expected range.");
    }
  };
}

function ResultTableRow(props: {
  value1: string | number;
  value2: string | number;
  value3: string | number;
  smallFont?: boolean;
}): ReactElement {
  const fontSize = props.smallFont ? "14px" : "18px";
  return (
    <Tr fontSize={fontSize}>
      <TFHCustomTd width="70px" value={props.value1} textAlign="left" />
      <TFHCustomTd width="35px" value={props.value2} textAlign="center" />
      <TFHCustomTd width="35px" value={props.value3} textAlign="center" />
    </Tr>
  );
}

export function ResultCo2TFH(props: {
  title: string;
  pre_cog_score: number;
  co2_result: string;
  pre_cog_subscore_ah?: number;
  pre_cog_subscore_eh?: number;
  pre_cog_subscore_uh?: number;
}): ReactElement {
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();
  const determineClass = useDetermineClass();

  useEffect(() => {
    if (!imageSrc || !imageSrc.WeatherImg[props.co2_result]) {
      preloadImage &&
        preloadImage("WeatherImg", {
          A: WeatherSunnyImgUrl,
          B: WeatherCloudyImgUrl,
          C: WeatherRainyImgUrl,
        });
    }
    switch (props.co2_result) {
      case "A":
        setResultComment(t("Co1DemoResult.resultA_comment"));
        break;
      case "B":
        setResultComment(t("Co1DemoResult.resultB_comment"));
        break;
      case "C":
        setResultComment(t("Co1DemoResult.resultC_comment"));
        break;
    }
  }, [setResultComment, t, imageSrc, preloadImage, props.co2_result]);

  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="flex-start">
        {/* タイトル */}
        <HeadingWithVerticalLine
          title={t("Result.analysis_results")}
          description={t("TFH.Co2DemoResult.Title")}
        />
        {/* Co2解析結果 解析結果*/}
        <Flex alignItems="center">
          <Image
            src={imageSrc ? imageSrc.WeatherImg[props.co2_result] : undefined}
            boxSize="15%"
          />
          <Text fontSize="2xl" ml={3}>
            {resultComment}
          </Text>
        </Flex>
        {/* Co2解析詳細テーブル */}
        <Table style={{ tableLayout: "fixed" }} bg="common.base">
          <Tbody>
            {/* 総合結果 */}
            <ResultTableRow
              value1={t("PJ.Co2DemoResult.Total")}
              value2={props.co2_result}
              value3={props.pre_cog_score.toFixed(2)}
            />
            {/* あー */}
            {props.pre_cog_subscore_ah !== undefined ? (
              <ResultTableRow
                value1={t("PJ.Co2DemoResult.Phrase1")}
                value2={determineClass(props.pre_cog_subscore_ah)}
                value3={props.pre_cog_subscore_ah.toFixed(2)}
                smallFont
              />
            ) : null}
            {/* えー */}
            {props.pre_cog_subscore_uh !== undefined ? (
              <ResultTableRow
                value1={t("PJ.Co2DemoResult.Phrase2")}
                value2={determineClass(props.pre_cog_subscore_uh)}
                value3={props.pre_cog_subscore_uh.toFixed(2)}
                smallFont
              />
            ) : null}
            {/* うー */}
            {props.pre_cog_subscore_eh !== undefined ? (
              <ResultTableRow
                value1={t("PJ.Co2DemoResult.Phrase3")}
                value2={determineClass(props.pre_cog_subscore_eh)}
                value3={props.pre_cog_subscore_eh.toFixed(2)}
                smallFont
              />
            ) : null}
          </Tbody>
        </Table>
        {/* Co2補足説明 */}
        <Text fontSize="sm" mt={5}>
          {t("TFH.Co2DemoResult.Guild.Title")}
        </Text>
        <Grid justifyContent="left" templateColumns="auto 1fr" gap={1} mt={1}>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Item1")}</Text>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Note1")}</Text>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Item2")}</Text>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Note2")}</Text>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Item3")}</Text>
          <Text fontSize="sm">{t("TFH.Co2DemoResult.Guild.Note3")}</Text>
        </Grid>
      </Flex>
    </Box>
  );
}
