import { BaseAnalysisEngine, BaseAnalysisResult } from "./baseAnalysisEngine";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { AnalysisEngineType, ApiEngineType } from "../types";
import { ResultCo2Demo } from "../components/molecules/ResultCo2Demo";
import { ResultCo2TFH } from "../components/molecules/ResultCo2TFH";

type co2_sub_parameters = {
  sub_scores: {
    sub_score_ah: number | "NaN";
    sub_score_eh: number | "NaN";
    sub_score_uh: number | "NaN";
  };
  details: Record<string, number>;
};

export type AnalysisResult = BaseAnalysisResult & {
  pre_cog_score: number;
  co2_result: string;
  co2_missing_info: Record<string, number>;
  co2_sub_parameters: co2_sub_parameters;
};

interface EngineProps {
  _analysisType?: AnalysisEngineType | null;
}

export class Co2Engine extends BaseAnalysisEngine<AnalysisResult> {
  _resultCaches: Record<string, AnalysisResult> = {};
  _engineType: ApiEngineType = "co2";
  _apiPath = "/analysis/co2";
  _analysisType: AnalysisEngineType | null = null;

  constructor(props?: EngineProps) {
    super();
    if (props && props._analysisType) {
      this._analysisType = props._analysisType;
    }
  }

  async renderResult(
    key: number,
    fileId: string,
    t: TFunction
  ): Promise<ReactElement> {
    const result = (await this.getResult(fileId)) as AnalysisResult;
    const pre_cog_score = result.pre_cog_score;
    const co2_result = result.co2_result;
    const pre_cog_subscore_ah =
      result.co2_sub_parameters.sub_scores.sub_score_ah == "NaN"
        ? undefined
        : result.co2_sub_parameters.sub_scores.sub_score_ah;
    const pre_cog_subscore_eh =
      result.co2_sub_parameters.sub_scores.sub_score_eh == "NaN"
        ? undefined
        : result.co2_sub_parameters.sub_scores.sub_score_eh;
    const pre_cog_subscore_uh =
      result.co2_sub_parameters.sub_scores.sub_score_uh == "NaN"
        ? undefined
        : result.co2_sub_parameters.sub_scores.sub_score_uh;

    return this._analysisType === "TFH" ? (
      <ResultCo2TFH
        key={key}
        title={t("Result.Dementia_analysis_result")}
        pre_cog_score={pre_cog_score}
        co2_result={co2_result}
        pre_cog_subscore_ah={pre_cog_subscore_ah}
        pre_cog_subscore_eh={pre_cog_subscore_eh}
        pre_cog_subscore_uh={pre_cog_subscore_uh}
      />
    ) : (
      <ResultCo2Demo
        key={key}
        title={t("Result.Dementia_analysis_result")}
        pre_cog_score={pre_cog_score}
        co2_result={co2_result}
        pre_cog_subscore_ah={pre_cog_subscore_ah}
        pre_cog_subscore_eh={pre_cog_subscore_eh}
        pre_cog_subscore_uh={pre_cog_subscore_uh}
      />
    );
  }
}
