import { RecordedData } from "../types";
import dayjs from "dayjs";
import { encodeWav } from "./wav";
import { APP_NAME } from "../environments";

export function generateVoiceFileData(
  allRecordedData: RecordedData[]
): FormData {
  const formData = new FormData();
  const now = dayjs().format("YYYY.MM.DD.HH.mm.ss");
  for (
    let phraseIndex = 0;
    phraseIndex < allRecordedData.length;
    ++phraseIndex
  ) {
    const recorded = allRecordedData[phraseIndex];
    if (!recorded.isSkipped) {
      const phrase = phraseIndex.toString().padStart(2, "0");
      // NOTE:アップロードされたファイルがあればそれを採用する（ファイル解析用）
      if (recorded.file) {
        formData.append("wav_files", recorded.file);
      } else {
        const wav = encodeWav(recorded.data, recorded.samplingRate);
        formData.append("wav_files", wav, `${phrase}_${now}.wav`);
      }

      if (recorded.co1VoiceType) {
        formData.append("cog_voice_types", recorded.co1VoiceType);
      }
      if (recorded.dysphagiaVoiceTypes) {
        formData.append("dysphagia_voice_types", recorded.dysphagiaVoiceTypes);
      }
      if (recorded.br1VoiceType) {
        formData.append("br1_voice_types", recorded.br1VoiceType);
      }
      if (recorded.sl1VoiceType) {
        formData.append("sl1_voice_types", recorded.sl1VoiceType);
      }
      if (recorded.co2VoiceType) {
        formData.append("co2_voice_types", recorded.co2VoiceType);
      }
      if (recorded.phrase) {
        formData.append("phrases", recorded.phrase);
      }
    }
  }
  formData.append(
    "app_version",
    `${APP_NAME.replaceAll(" ", "_")}-${__GIT_DESCRIBE_REVISION__}`
  );
  return formData;
}
