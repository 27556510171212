import { ReactElement, useContext, useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  Tbody,
  Text,
  Tr,
} from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import WeatherSunnyImgUrl from "../../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../../assets/images/weather/rainy.png";
import { ResultDashboardData, ResultSubParam } from "../../types";
import { HeadingWithVerticalLine } from "../atoms/HeadingWithVerticalLine";
import { TFHCustomTd } from "../atoms/TFHCustmTd";

function formatScore(score: string): string {
  return score.replace("(Posi)", "(+)").replace("(Nega)", "(-)");
}

export function ResultMi2TFH({
  result,
}: {
  result: ResultDashboardData;
}): ReactElement {
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();

  const findSubParam = (title: string): ResultSubParam | undefined => {
    return result.subParams.find(
      (param) => param.title.toLowerCase() === title.toLowerCase()
    );
  };

  const cpfParam = findSubParam("Result.Mi2_cpf");
  const zhParam = findSubParam("Result.Mi2_zh");

  const mi12Score = result.mainParam.value;
  const cpfScore =
    cpfParam && cpfParam.value ? cpfParam.value.toString() : "Error";
  const zhScore = zhParam && zhParam.value ? zhParam.value.toString() : "Error";

  useEffect(() => {
    if (mi12Score && imageSrc && !imageSrc.WeatherImg[mi12Score]) {
      preloadImage &&
        preloadImage("WeatherImg", {
          A: WeatherSunnyImgUrl,
          B: WeatherCloudyImgUrl,
          C: WeatherRainyImgUrl,
        });
    }
  }, [imageSrc, mi12Score, preloadImage, result]);

  useEffect(() => {
    if (mi12Score) {
      switch (mi12Score) {
        case "A":
          setResultComment(t("Mi2DemoResult.resultA_comment"));
          break;
        case "B":
          setResultComment(t("Mi2DemoResult.resultB_comment"));
          break;
        case "C":
          setResultComment(t("Mi2DemoResult.resultC_comment"));
          break;
        default:
          setResultComment(""); // 未知の結果の場合
      }
    }
  }, [mi12Score, result, t]);

  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="flex-start">
        {/* タイトル */}
        <HeadingWithVerticalLine
          title={t("Result.analysis_results")}
          description={t("TFH.Mi2DemoResult.Title")}
        />
        {/* Mi2解析結果 */}
        <Flex alignItems="center">
          {mi12Score && (
            <Image
              src={imageSrc ? imageSrc.WeatherImg[mi12Score] : undefined}
              boxSize="15%"
            />
          )}
          <Text fontSize="2xl" ml={3}>
            {resultComment}
          </Text>
        </Flex>
        {/* Mi2解析詳細 */}
        <Table style={{ tableLayout: "fixed" }} bg="common.base">
          <Tbody>
            <Tr fontSize="18px">
              <TFHCustomTd
                value={t("TFH.Mi2DemoResult.Table.Cpf")}
                textAlign="left"
              />
              <TFHCustomTd value={formatScore(cpfScore)} />
            </Tr>
            <Tr fontSize="18px">
              <TFHCustomTd
                value={t("TFH.Mi2DemoResult.Table.Mdvi")}
                textAlign="left"
              />
              <TFHCustomTd value={formatScore(zhScore)} />
            </Tr>
          </Tbody>
        </Table>
        {/* Mi2説明テーブル */}
        <Text fontSize="sm" mt={5}>
          {t("TFH.Mi2DemoResult.Guild.Title")}
        </Text>
        <Grid justifyContent="left" templateColumns="auto 1fr" gap={1} mt={1}>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Item1")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Note1")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Item2")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Note2")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Item3")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Note3")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Item4")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Note4")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Item5")}</Text>
          <Text fontSize="sm">{t("TFH.Mi2DemoResult.Guild.Note5")}</Text>
        </Grid>
      </Flex>
    </Box>
  );
}
