import { ENABLED_ENGINES } from "../environments";
import { AnalysisEngineType, TokenEngineType } from "../types";
import { decodeJwt } from "./decodeJwt";

function getEngineTypes(accessToken: string): object {
  const decodedAccessToken = decodeJwt(accessToken);
  return decodedAccessToken.engineTypes as object;
}

function getExpandedEngines(): TokenEngineType[] {
  return ENABLED_ENGINES.reduce<TokenEngineType[]>((acc, val) => {
    if (val === "Cognitive") {
      return acc.concat(["co1", "np1"]);
    } else if (val === "Mi1_Mi2") {
      return acc.concat(["mi1", "mi2"]);
    } else if (val === "UIX") {
      return acc.concat(["mi1", "mi2", "co1", "br1"]);
    } else if (val === "cog") {
      return acc.concat(["co1"]);
    } else if (val === "Co1Demo") {
      return acc.concat(["co1"]);
    } else if (val === "NTT") {
      return acc.concat(["mi1"]);
    } else if (val === "Mi2Demo") {
      return acc.concat(["mi2"]);
    } else if (val === "Co2Demo") {
      return acc.concat(["co2"]);
    } else if (val === "TFH") {
      return acc.concat(["mi2", "co2"]);
    } else {
      return acc.concat(val);
    }
  }, []);
}

// エンジン解析可否確認
export function checkEngineEnabled(accessToken: string): boolean {
  const engine_types = getEngineTypes(accessToken);
  const expandedEngines = getExpandedEngines();
  return (
    "all" in engine_types ||
    expandedEngines.some((key) => Object.keys(engine_types).includes(key))
  );
}

// 全エンジン解析可否確認
export function checkAllEngineEnabled(engine_types: object): boolean {
  const expandedEngines = getExpandedEngines();
  return (
    "all" in engine_types ||
    expandedEngines.every((key) =>
      Object.keys(engine_types).includes(key as AnalysisEngineType)
    )
  );
}
