import { ReactElement, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Flex, Text } from "@chakra-ui/react";
import { RecordedData } from "../../types";
import { useTranslation } from "react-i18next";
import { useToastMessage } from "../../hooks/useToastMessage";

type FileUploadZoneProps = {
  id: number;
  phrase: string;
  co1VoiceType?: string;
  br1VoiceType?: string;
  sl1VoiceType?: string;
  co2VoiceType?: string;
  onUploadComplete: (key: number, data: RecordedData) => void;
};

export function FileUploader({
  id,
  phrase,
  co1VoiceType,
  br1VoiceType,
  sl1VoiceType,
  co2VoiceType,
  onUploadComplete,
}: FileUploadZoneProps): ReactElement {
  const { t } = useTranslation();
  const toastMessage = useToastMessage();
  const [uploadFileName, setUploadFileName] = useState<string>(
    t("FileAnalysis.upload_comment")
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (acceptedFiles.length > 1) {
        toastMessage(t("Error.overUploadCount"));
        return;
      }
      if (file) {
        const fileName: string = acceptedFiles[0]["name"];
        const reader = new FileReader();
        reader.onloadend = () => {
          const arrayBuffer = reader.result as ArrayBuffer;
          const audioContext = new AudioContext();
          audioContext
            .decodeAudioData(arrayBuffer)
            .then((audioBuffer) => {
              const data = audioBuffer.getChannelData(0);
              const samplingRate = audioBuffer.sampleRate;
              onUploadComplete(id, {
                file,
                data,
                samplingRate,
                phrase,
                isSkipped: false,
                co1VoiceType,
                br1VoiceType,
                sl1VoiceType,
                co2VoiceType,
              });
            })
            .catch((err) => console.error(err));
        };
        reader.readAsArrayBuffer(file);
        setUploadFileName(
          (fileName + t("FileAnalysis.uploaded_comment")) as string
        );
      }
    },
    [
      toastMessage,
      t,
      onUploadComplete,
      id,
      phrase,
      co1VoiceType,
      br1VoiceType,
      sl1VoiceType,
      co2VoiceType,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "audio/wav": [".wav"],
    },
  });

  return (
    <Flex width="100%" flexDirection="column" justifyContent="center">
      <Flex
        {...getRootProps()}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={2}
        width="100%"
        minHeight="120px"
        border="2px dashed #888"
        bg="white"
      >
        <input {...getInputProps()} />
        <Text mb={2}>{uploadFileName}</Text>
        <Button size="sm" variant="btn_primary" width="200px" height="40px">
          {t("FileAnalysis.upload_button")}
        </Button>
      </Flex>
    </Flex>
  );
}
