export type AnalysisEngineType =
  | "Cognitive"
  | "cog"
  | "co1"
  | "np1"
  | "Mi1_Mi2"
  | "dysphagia"
  | "mi2"
  | "br1"
  | "UIX"
  | "Co1Demo"
  | "NTT"
  | "sl1"
  | "Mi2Demo"
  | "Co2Demo"
  | "TFH";
export type TokenEngineType =
  | "mdvi"
  | "cf"
  | "dysphagia"
  | "mi1"
  | "mi2"
  | "cog"
  | "co1"
  | "np1"
  | "br1"
  | "sl1"
  | "co2";
export type ApiEngineType =
  | "mdvi"
  | "cf"
  | "dysphagia"
  | "mimosys"
  | "mi1"
  | "mi2"
  | "cog"
  | "co1"
  | "np1"
  | "br1"
  | "sl1"
  | "co2";

export function isValidAnalysisEngineType(
  engineType: unknown
): engineType is AnalysisEngineType {
  return (
    typeof engineType === "string" &&
    (engineType === "Cognitive" ||
      engineType === "cog" ||
      engineType === "co1" ||
      engineType === "np1" ||
      engineType === "Mi1_Mi2" ||
      engineType === "dysphagia" ||
      engineType === "mi2" ||
      engineType === "br1" ||
      engineType === "UIX" ||
      engineType === "Co1Demo" ||
      engineType === "NTT" ||
      engineType === "sl1" ||
      engineType === "Mi2Demo" ||
      engineType === "Co2Demo" ||
      engineType === "TFH")
  );
}

export type LanguageType = "ja" | "en";
export function isValidLanguageType(lang: unknown): lang is LanguageType {
  if (typeof lang !== "string") return false;
  return lang === "ja" || lang === "en";
}

export function isObject(x: unknown): x is Record<string, unknown> {
  return typeof x === "object" && x != null && !Array.isArray(x);
}

export type AppApiError = {
  status_code?: number;
  detail: string | Record<string, string> | Array<Record<string, string>>;
};
export function isValidAppApiError(err: unknown): err is AppApiError {
  if (!isObject(err)) {
    return false;
  }
  return (
    (typeof err.status_code !== "number" ||
      typeof err.status_code !== "undefined") &&
    (typeof err.detail === "string" ||
      isObject(err.detail) ||
      Array.isArray(err.detail))
  );
}

export type ResultMainParam = {
  title: string;
  subtitle?: string;
  value: number | string | undefined;
  border?: string;
  maxWidth?: string;
};

export type ResultSubParam = {
  title: string;
  value: number | string | undefined;
  color?: string;
};

export type ResultSupplementaryInfo = {
  title: string;
  value: number | undefined;
};

export type TableAlign = "left" | "center";

export type ResultDiagramIndex = {
  title: string;
  width: string;
  subWidth?: string;
  align?: TableAlign;
};

export type ResultDiagramParam = {
  width?: string;
  value: string | number;
  align?: TableAlign;
  backgroundColor?: string;
};

export type ResultDashboardData = {
  mainParam: ResultMainParam;
  subParams: ResultSubParam[];
  supplementaryInfo: ResultSupplementaryInfo[];
  diagramIndex: ResultDiagramIndex[];
  diagramParams: ResultDiagramParam[][];
  phrases?: string[];
};

export type DysphagiaResultSummary = {
  supplementaryInfo: ResultSupplementaryInfo[];
  imgUrl: string;
  result: string;
  score: string;
  comment: string | undefined;
  resultReliabilityMessage?: string;
};

export type AnalysisErrorReason = {
  code: string;
  message: string;
};

export function isValidAnalysisErrorReason(
  reason: unknown
): reason is AnalysisErrorReason {
  if (!isObject(reason)) return false;
  return typeof reason.code === "string" && typeof reason.message === "string";
}

export type SelectionLoginUser = {
  id?: number;
  email: string;
  password: string;
  nickname: string;
  color: string;
};

export type RecordedData = {
  file?: File;
  data: Float32Array;
  samplingRate: number;
  phrase: string;
  isSkipped: boolean;
  co1VoiceType?: string;
  dysphagiaVoiceTypes?: string;
  br1VoiceType?: string;
  sl1VoiceType?: string;
  co2VoiceType?: string;
};

export type AccessToken = {
  access_token: string;
  expires_in: number;
};

export type AnalysisState = "IDLE" | "UPLOADING" | "ANALYZING";

export type SoundVisualizerRef = {
  draw: (
    rawDara: number,
    isTooLoud: boolean,
    isDetectedVolume: boolean
  ) => void;
};

export type PhraseText = {
  title: string;
  phrase: string;
  helperText?: string;
  baseTextSize?: string;
  mdTextSize?: string;
  helperTextSize?: string;
  co1VoiceType?: string;
  br1VoiceType?: string;
  sl1VoiceType?: string;
  co2VoiceType?: string;
};

export type AppType = "base" | "sompo" | "uix" | "ntt";

export type themeType = "sakura_light" | "uix_light" | "ntt_light";

export type CheckVolumeResult =
  | "OK"
  | "Error.allDataIsSilent"
  | "Error.belowVolumeThreshold"
  | "Error.cannotDetectVoice";

type MeanResultCommon = {
  data_path: string;
  num: number;
  mean?: number;
};

export type MeanResult = MeanResultCommon[];

export type LoginFormData = {
  user_id: string;
  password: string;
};

export type LoginErrorCode =
  | "INVALID_TOKEN"
  | "INVALID_GOOGLE_TOKEN"
  | "ENGINE_DISABLED";
