import axios, { AxiosError } from "axios";
import { TOKEN_INFO_SESSION_STORAGE_KEY } from "../constants";
import {
  AUTOLOGIN_PASS,
  AUTOLOGIN_USER,
  BASE_URL,
  ENABLE_AUTO_LOGIN,
} from "../environments";
import { AccessTokenInfo } from "../store";
import dayjs from "dayjs";
import { getTokenApi } from "./token";

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

export const appAxios = axios.create({ baseURL: BASE_URL });

appAxios.interceptors.request.use(async (config) => {
  const tokenInfoStr = sessionStorage.getItem(TOKEN_INFO_SESSION_STORAGE_KEY);
  const tokenInfo = JSON.parse(tokenInfoStr || "null");
  if (tokenInfo) {
    config.headers = config.headers || {};
    config.headers["Authorization"] = `Bearer ${tokenInfo.access_token}`;
  }
  return config;
});

// レスポンスインターセプター
appAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    console.log("response error", error);
    const originalRequest = error.config;
    const url = originalRequest.url;

    // 自動ログインの場合
    if (ENABLE_AUTO_LOGIN) {
      // 401 Unauthorizedエラーの処理
      // 無限ループを防ぐためToken取得以外
      if (
        error?.response?.status === 401 &&
        url != "/token" &&
        !originalRequest._retry
      ) {
        try {
          // トークンの再取得
          const data = await getTokenApi(AUTOLOGIN_USER, AUTOLOGIN_PASS);
          const now = dayjs();
          const tokenInfo: AccessTokenInfo = {
            access_token: data.access_token,
            expired_at: now.add(data.expires_in, "second").toISOString(),
          };
          // セッションストレージに保存
          // Note: セッションストレージに保存してもATOMは更新されない
          // 今回の対応は自動ログインのための限定的なもの
          sessionStorage.setItem(
            TOKEN_INFO_SESSION_STORAGE_KEY,
            JSON.stringify(tokenInfo)
          );

          originalRequest._retry = true;
          return appAxios(originalRequest); // 再度APIリクエストを実行
        } catch (tokenError) {
          console.error("Token refresh failed:", tokenError);
          return Promise.reject(tokenError);
        }
      }
    }
    return Promise.reject(error);
  }
);
