import {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { Link } from "react-router-dom";
import { IoSettingsSharp } from "react-icons/io5";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  allEngineEnabledAtom,
  engineTypesAtom,
} from "../store";
import { Layout } from "../components/atoms/Layout";
import {
  CO1_DEMO_MODE,
  ENABLE_AUTO_LOGIN,
  ENABLE_FILE_ANALYSIS,
  ENABLED_ENGINES,
} from "../environments";
import { getSelectableEngineList } from "./engines";
import { ImageContext } from "../components/atoms/ImageProvider";
import WeatherSunnyImgUrl from "../assets/images/weather/sunny.png";
import WeatherCloudyImgUrl from "../assets/images/weather/cloudy.png";
import WeatherRainyImgUrl from "../assets/images/weather/rainy.png";
import AnalyzeVoiceImgUrl from "../assets/images/base/analyze_voice.svg";
import AnalyzeFileImgUrl from "../assets/images/base/analyze_file.svg";
import { HomeImage } from "../components/atoms/HomeImage";
import TfhGraphSampleImgUrl from "../assets/images/tfh/sampleGraph.png";

export function Home(): ReactElement {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [, setAccessTokenInfo] = useAtom(accessTokenInfoAtom);
  const dialogCancelRef = useRef(null);
  const [isTouchDevice] = useMediaQuery("(hover: none)");
  const { preloadImage } = useContext(ImageContext);

  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  if (tokenInfo === null) throw new Error("no token");

  const [engineTypes, setEngineTypes] = useAtom(engineTypesAtom);
  const [allEnginesEnabled, setAllEngineEnable] = useAtom(allEngineEnabledAtom);

  const startTo = useMemo(() => {
    const url_list = getSelectableEngineList(engineTypes, allEnginesEnabled);
    if (url_list.length == 1)
      return "../engines/" + url_list[0] + "/questionnaires";
    else return "../engines";
  }, [allEnginesEnabled, engineTypes]);

  // 画像のプリロード
  useEffect(() => {
    if (ENABLE_FILE_ANALYSIS && preloadImage) {
      preloadImage("images", {
        AnalyzeVoiceImg: AnalyzeVoiceImgUrl,
        AnalyzeFileImg: AnalyzeFileImgUrl,
      });
    }
    if (CO1_DEMO_MODE && preloadImage) {
      preloadImage("WeatherImg", {
        A: WeatherSunnyImgUrl,
        B: WeatherCloudyImgUrl,
        C: WeatherRainyImgUrl,
      });
    }
    if ("TFH" in ENABLED_ENGINES && preloadImage) {
      preloadImage("TfhImg", {
        GraphSampleImg: TfhGraphSampleImgUrl,
      });
    }
  }, [preloadImage]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  const handleLogout = (): void => {
    setAccessTokenInfo(null);
    setEngineTypes([]);
    setAllEngineEnable(false);
  };

  return (
    <Layout
      minHeight="100%"
      paddingY={{ base: 5, md: 12 }}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex justifyContent="space-between" alignItems="center">
        <IconButton
          variant="ghost"
          aria-label="Setting"
          icon={<Icon as={IoSettingsSharp} boxSize={6} color="gray.500" />}
          as={Link}
          to="../calibration"
          _hover={isTouchDevice ? { bg: "white" } : undefined}
        />
        {!ENABLE_AUTO_LOGIN && (
          <Button
            variant="btn_primary"
            lineHeight={1}
            onClick={() => setIsOpenDialog(true)}
          >
            {t("Home.logout")}
          </Button>
        )}
      </Flex>
      <Center maxW="full" my={5}>
        <HomeImage />
      </Center>
      <Button
        as={Link}
        to={startTo}
        size="lg"
        variant="btn_primary"
        width="full"
      >
        {t("Home.start")}
      </Button>

      {/* ログアウト確認モーダル */}
      <AlertDialog
        isCentered
        isOpen={isOpenDialog}
        leastDestructiveRef={dialogCancelRef}
        onClose={() => setIsOpenDialog(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Home.logout")}
            </AlertDialogHeader>

            <AlertDialogBody
              my={12}
              fontSize="lg"
              textAlign="center"
              fontWeight="normal"
            >
              {t("Home.logoutConfirm")}
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="space-between">
              <Button
                variant="ghost"
                ref={dialogCancelRef}
                onClick={() => setIsOpenDialog(false)}
              >
                {t("Modal.cancel")}
              </Button>
              <Button variant="ghost" onClick={handleLogout}>
                {t("Modal.yes")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Layout>
  );
}
